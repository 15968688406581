<template>

  <!-- Wrapper element is required for correct cleanup. -->

  <div>
    <input
      ref="typeahead"
      type="text"
      class="form-control"
      autoComplete="off"
      :value="inputValue"
      :required="required"/>
  </div>

</template>

<script>

import {customers} from '@/services';
import {displayName} from '@hellochef/shared-js/helpers';
import Bloodhound from 'corejs-typeahead';
import $ from 'jquery';
import uniqueId from 'lodash/uniqueId';

export default {
  props: [

    'required',
    'value',
  ],
  computed: {

    inputValue() {
      if (!this.value) { return ''; }

      return `${displayName(this.value.user)} (${this.value.user.email})`;
    },
  },

  beforeDestroy() {
    $(this.$refs.typeahead).typeahead('destroy');
  },
  mounted() {
    const id = uniqueId();

    const items = new Bloodhound({

      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {

        prepare: function(query, settings) {
          // Add the query to the settings object for usage in our custom transport function.
          // We also need to update the URL, as Bloodhound uses this to determine if a request
          // should be made in the first place (cached results are used for the same resource URLs).

          settings.query = query;
          settings.url = 'unused' + id + query;

          return settings;
        },
        rateLimitBy: 'debounce',
        rateLimitWait: 500,
        transport: (settings, onSuccess, onError) => {
          customers.getByParameters({

            query: settings.query,
          })
            .then(result => { onSuccess(result.items); })
            .catch(() => { onError(); });
        },
        url: 'unused' + id, // We use a custom transport function, but the URL is a required parameter and needs to be unique to disable shared Bloodhound caching.
      },
    });

    const $el = $(this.$refs.typeahead);

    $el.typeahead({

      highlight: true,
      hint: true,
      minLength: 0,
    },
    {
      display: obj => `${displayName(obj.user)} (${obj.user.email})`,
      source: items,
    });

    $el.on('typeahead:select', (e, obj) => {
      this.$emit('input', obj);
    });
  },
};

</script>
